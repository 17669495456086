body {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    background-color: #E5E5E5;
}

.h-h {
    position: relative;
    background: #0d4679;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 65%;
        height: 100%;
        background-color: #023E73;
        clip-path: polygon(0 0, 100% 0, 63% 100%, 0 69%);
        transform: translateY(0px);
    }

    header {
        border-bottom: 1px solid rgb(255 255 255 / 10%);
        position: relative;
        z-index: 1;

        nav {
            position: relative;
            z-index: 1;
            display: flex;
            padding: 30px 0;

            @media screen and (max-width : 968px) {
                padding: 30px 15px;
            }

            img {
                display: flex;
                flex-direction: column;
                margin-right: auto;
                max-width: 200px;
                width: 100%;

                z-index: 3;
            }

            #box {
                position: absolute;
                right: 40px;
                top: 50%;
                display: none;
            }

            .click {
                position: absolute;
                right: 20px;
                top: 30px;
                display: none;
                z-index: 3;

                @media screen and (max-width : 968px) {
                    display: block;
                }
            }

            ul {
                display: flex;
                list-style: none;
                padding: 0;
                margin-bottom: 0;
                column-gap: 40px;
                z-index: 1;

                @media screen and (max-width : 968px) {
                    display: none;
                }

                li {
                    a {
                        font-size: 18px;
                        font-weight: 300;
                        color: #E5E5E5;
                        text-decoration: none;

                        @media screen and (max-width : 968px) {
                            font-size: 30px;
                            padding-bottom: 20px;
                            display: block;
                        }

                        &:hover{
                            color: #108CB1;
                        }
                    }
                }


            }
        }
    }

    #box:checked~ul {
        transform: translateX(0%);
    }

    .hero {
        background-color: #0d4679;
        padding: 100px 40px;

        @media screen and (max-width : 768px) {
            padding: 50px 20px;
        }


        .hero-heading {
            h1 {
                font-size: 50px;
                font-weight: 300;
                color: #E5E5E5;
                position: relative;
                z-index: 0;

                @media screen and (max-width : 768px) {
                    font-size: 30px;
                }

                span {
                    color: #108CB1;
                    font-weight: 500;
                }
            }

            button {
                background-color: #108CB1;
                padding: 18px 48px;
                border: 0;
                font-size: 16px;
                line-height: 23.04px;
                text-align: center;
                color: #ffffff;
                font-weight: 500;
                margin-top: 80px;
                border-radius: 4px;
                position: relative;
                z-index: 0;

            }
        }
    }
}

.heading {
    font-weight: 700;
    color: #023E73;
    font-size: 30px;
    line-height: 43.2px;
    margin-bottom: 0;
}

.about {
    position: relative;
    padding: 0 40px;

    @media screen and (max-width : 992px) {
        padding: 0px;
    }

    .about-h {
        padding-top: 100px;

        @media screen and (max-width : 992px) {
            padding-top: 20px;
        }

        h4 {
            font-weight: 500;
            line-height: 28.8px;
            color: #1B2833;
            margin-bottom: 0;
            padding-bottom: 89px;
            font-size: 18px;

            @media screen and (max-width : 992px) {
                padding-bottom: 39px;
            }
        }

        p {
            font-weight: 500;
            line-height: 28.8px;
            color: #1B2833;
            margin-bottom: 0;
            font-size: 18px;
        }
    }

    .boxes-items {
        transform: translateY(-60px);

        @media screen and (max-width : 992px) {
            transform: translateY(0px);
            margin-top: 30px;
        }

        .item {
            background-color: #ffffff;
            padding: 40px;
            border-radius: 8px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;

            @media screen and (max-width : 568px) {
                padding: 15px;
            }

            h3 {
                font-weight: 500;
                font-size: 18px;
                line-height: 25.9px;
                color: #023E73;
            }

            p {
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                line-height: 23.04px;
                margin-bottom: 0;
            }
        }
    }
}

.expertise {

    .exp-img {
        transform: translateY(-130px);

        @media screen and (max-width : 992px) {
            transform: translateY(-0px);
        }

        img {
            width: 100%;
        }
    }

    .exp-h {
        padding-left: 40px;
        padding-right: 40px;

        @media screen and (max-width : 992px) {
            padding-left: 0px;
            padding-right: 0px;
        }

        h3 {
            @media screen and (max-width : 992px) {
                padding-top: 30px;

            }
        }


        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 25.92px;
            color: #1B2833;
            margin-top: 50px;
        }
    }

    .boxes-listing {
        margin-top: 48px;
        margin-left: -130px;
        // display: flex;
        // align-items: flex-start;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        gap: 10px;
        position: relative;
        z-index: 1;
        padding-right: 40px;
        padding-bottom: 70px;

        @media screen and (max-width : 992px) {
            margin-left: 0px;
            padding-right: 0;
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        }

        .new-box {
            background-color: #FFFFFF;
            padding: 30px;
            border-radius: 8px;
            margin-bottom: 10px;
            // max-width: calc(33.3% - 10px);
            // flex: 1 0 200px;

            @media screen and (max-width : 992px) {
                margin-left: 0px;
                padding-right: 0;
            }

            h3 {
                font-size: 18px;
                font-weight: 500;
                color: #023E73;
                line-height: 25.92px;
                margin-bottom: 0;
                padding-bottom: 24px;
            }

            p {
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 400;
                line-height: 20.16px;
                color: #000000;
            }
        }
    }
}

.service {
    padding: 0 40px;
    margin-bottom: 40px;

    @media screen and (max-width : 992px) {
        padding: 0 0px;
    }

    .card-create {
        background-color: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        gap: 20px;
        min-height: 450px;
        // max-height: 700px;
        margin-top: 48px;

        @media screen and (max-width : 992px) {
            flex-direction: column;
        }

        img {
            width: 200px;
            // height: 100%;
            object-fit: cover;

            @media screen and (max-width : 992px) {
                width: 100%;
                height: 300px;
            }

        }

        .card-list {
            padding: 40px 20px 0;

            @media screen and (max-width : 992px) {
                padding: 20px;
            }

            h3 {
                font-weight: 500;
                font-size: 18px;
                color: #023E73;
                line-height: 25.92px;
                margin-bottom: 0;
                padding-bottom: 25px;

            }

            ul {
                li {
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20.16px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.client-speak {
    position: relative;
    z-index: 2;

    .client {
        background-color: #108CB1;
        padding: 70px;
        border-radius: 8px;
        transform: translateY(100px);

        @media screen and (max-width : 768px) {
            padding: 20px;
            margin-top: -100px;
        }

        .start {
            font-weight: 500;
            font-size: 42px;
            line-height: 47.04px;
            color: #023E73;
            margin-bottom: 0;

            @media screen and (max-width : 768px) {
                font-size: 28px;
                padding-bottom: 10px;
            }
        }

        .jor-heading {
            p {
                font-weight: 500;
                font-size: 20px;
                color: #E5E5E5;
                line-height: 28.8px;
                margin-bottom: 0;
                padding-bottom: 28px;
            }


        }
    }
}

.button-submit {
    font-weight: 500;
    color: #E5E5E5;
    line-height: 23.04px;
    text-align: center;
    padding: 10px 50px;
    background-color: #023E73;
    border: 0px;
    border-radius: 50px;
    border: 1px solid #108CB1;
    outline: 3px solid #023E73;

    &:focus {
        outline: 3px solid #023E73 !important;
    }
}

footer {
    background-color: #0d4679;
    position: relative;
    padding: 200px 50px 80px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 65%;
        height: 100%;
        background-color: #023E73;
        clip-path: polygon(0 0, 100% 0, 63% 100%, 0 69%);
        transform: translateY(0px);

    }

    .f-m {
        position: relative;
        z-index: 1;

        img {
            max-width: 100%;
        }
    }

    .list-f {
        position: relative;
        z-index: 1;
        // text-align: center;
        max-width: 200px;
        margin: 0 auto;

        @media screen and (max-width : 767px) {
            margin: 40px 0;
        }

        ul {
            list-style: none;
            margin-bottom: 0;

            @media screen and (max-width : 992px) {
                padding: 0;
            }

            li {
                padding-bottom: 20px;

                a {
                    font-weight: 400;
                    color: #E5E5E5;
                    font-size: 18px;
                    line-height: 25.92px;
                    text-decoration: none;
                    
                    &:hover{
                        color: #108CB1;
                    }
                }

            }
        }
    }

    .email {
        position: relative;
        z-index: 1;

        a {
            font-weight: 400;
            color: #ffff;
            font-size: 18px;
            line-height: 25.92px;
        }
    }
}